import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

const contact = () => {
  return (
    <Layout>
      <Helmet>
        <title>Contact Us | InsideHero</title>
        <meta name="description" content="Reach out to us." />
      </Helmet>

      <div className="container">
        <section className="contactus-section">
          <div className="contact-left-desc">
            <h3>Need help?</h3>
            <h2>No problem!</h2>

            <div className="contact-left-desc-wrap">
              <p>If you have any questions, please contact us at</p>
              <p className="contact-left-desc-wrap-email">
                <a href="mailto:support@insidehero.io">support@insidehero.io</a>
              </p>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default contact
